<template>
    <div class="video-container">
        <div class="content">

            <div class="upload-wrapper" v-show="!isUploaded">
                <Upload
                    :cosName="cosName"
                    :prefix="prefix"
                    :filename="filename" 
                    @uploadSuccess="uploadSuccess"
                ></Upload>
            </div>
            <div class="video-wrapper" id="video-wrapper" v-show="isUploaded">
                <!-- <video id='my-video' class='video-js vjs-big-play-centered' controls preload='auto' data-setup='{}' v-if="videoUrl">
                    <source :src="videoUrl" type="video/mp4">
                    <p class='vjs-no-js'>
                        视频加载失败
                        <a href='https://videojs.com/html5-video-support/' target='_blank'>supports HTML5 video</a>
                    </p>
                </video> -->
                <video id="my-video" class="video-js vjs-big-play-centered">
                    <p class="vjs-no-js">
                        To view this video please enable JavaScript, and consider upgrading to a
                        web browser that
                        <a href="https://videojs.com/html5-video-support/" target="_blank">
                        supports HTML5 video
                        </a>
                    </p>
                </video>
            </div>
        </div>
    </div>
</template>

<script>
import videojs from 'video.js'
import Upload from '@/views/components/widget/Upload';
import { mapGetters } from 'vuex'

import { getCourseInfo, getSectionInfo } from '@/utils/common'
import { genResId } from '@/api/web'
import { getAdminSectionData, saveAdminSectionRes } from '@/api/admin'

export default {
    name: 'my-video',
    data: function() {
        return {
            curEnv: '',

            resId: '',
            filename: '', //此处视频比较特殊，因为需要转码，所以需要存放在一个目录中

            cosName: 'video',
            prefix: 'video',
            
            isUploaded: false,
            // isUploading: false,

            player: null,
            sources: [],
            index: 0,
        }
    },
    computed: {
        ...mapGetters([
            'pid',
            'cid',
            'sid',
        ]),
    },
    activated() {
    },
    deactivated() {
        this.resId = ''
        this.filename = ''

        if (this.player) {
            this.player.reset()
        }
        // this.isUploading = false
        this.isUploaded = false
    },
    mounted() {
        // 加载userInfo
        this.loadUserInfo();
    },
    methods: {
        getCourseInfo,
        getSectionInfo,
        async loadUserInfo() {
            await this.$store.dispatch('user/getUserInfo');
        },
        loadPage(cid, sid, pid, curEnv = null) {
            if (curEnv) {
                this.curEnv = curEnv
            }

            if (this.curEnv == 'classroom') {
                this.loadClassroomData(cid, sid)

            } else if (this.curEnv == 'editor') {
                this.loadEditorData(cid, sid)
            }
        },
        async loadClassroomData(cid, sid) {
            this.$store.commit('web/SetCourseId', cid)
            this.$store.commit('web/SetSectionId', sid)

            // 获取小节resId
            let res = await getAdminSectionData({ cid, sid })
            if (res && res.data && res.data.resId != "") {
                this.resId = res.data.resId
                this.filename = this.resId + '/' + this.resId
            
                this.$store.commit('web/SetProjectId', this.resId)
            }

            // 加载上课信息
            this.getCourseInfo(cid)

            // 加载小节信息
            this.getSectionInfo(sid)

            // 加载小节数据
            this.loadData()
        },
        async loadEditorData(cid, sid) {
            this.$store.commit('web/SetCourseId', cid)
            this.$store.commit('web/SetSectionId', sid)
            
            // 获取小节resId
            let res = await getAdminSectionData({ cid, sid })
            if (res && res.data && res.data.resId != "") {
                this.resId = res.data.resId
                this.filename = this.resId + '/' + this.resId

                this.$store.commit('web/SetProjectId', this.resId)
            }

            // 加载小节信息
            this.getSectionInfo(sid)

            // 加载小节数据
            this.loadData()
        },
        async loadData() {
            if (this.filename == "") {
                let res = await genResId()
                if (res && res.data) {
                    this.resId = res.data.resId
                    this.filename = this.resId + '/' + this.resId
                }
            } else {
                this.isUploaded = true

                this.$nextTick(() => {
                    this.loadVideo()
                })
            }
        },
        loadVideo() {
            if (this.resId == "") {
                return
            }

            let videoUrl_1 = "https://video-1253970464.cos.ap-beijing.myqcloud.com/video/" + this.filename + ".m3u8"
            let videoUrl_2 = "https://video-1253970464.cos.ap-beijing.myqcloud.com/video/" + this.filename + ".mp4"

            this.sources = [ // 视频源
                {
                    src: videoUrl_1,
                    type: 'application/x-mpegURL',
                    // poster: '//vjs.zencdn.net/v/oceans.png'
                },
                {
                    src: videoUrl_2,
                    type: 'video/mp4',
                }
            ]

            if (!this.player) {
                this.player = videojs(document.getElementById('my-video'), {
                    controls: true, // 是否显示控制条
                    // poster: 'xxx', // 视频封面图地址
                    preload: 'auto',
                    autoplay: true,
                    // fluid: true, // 自适应宽高
                    language: 'zh-CN', // 设置语言
                    muted: false, // 是否静音
                    inactivityTimeout: false,
                    controlBar: { // 设置控制条组件
                        /* 设置控制条里面组件的相关属性及显示与否
                        'currentTimeDisplay':true,
                        'timeDivider':true,
                        'durationDisplay':true,
                        'remainingTimeDisplay':false,
                        volumePanel: {
                        inline: false,
                        }
                        */
                        /* 使用children的形式可以控制每一个控件的位置，以及显示与否 */
                        children: [
                            {name: 'playToggle'}, // 播放按钮
                            {name: 'currentTimeDisplay'}, // 当前已播放时间
                            {name: 'progressControl'}, // 播放进度条
                            {name: 'durationDisplay'}, // 总时间
                            { // 倍数播放
                                name: 'playbackRateMenuButton',
                                'playbackRates': [0.5, 1, 1.5, 2, 2.5]
                            },
                            {
                                name: 'volumePanel', // 音量控制
                                inline: false, // 不使用水平方式
                            },
                            {name: 'FullscreenToggle'} // 全屏
                        ]
                    },
                }, function (){
                    // console.log('视频可以播放了');
                });
            }
            
            let that = this

            videojs.hook('beforeerror', (player, err) => {
                // console.log('hook - beforeerror', that.index, player.src(), err)
                // Video.js 在切换/指定 source 后立即会触发一个 err=null 的错误，这里过滤一下
                if (err !== null) {
                    that.index = (that.index + 1) % that.sources.length
                    player.src(that.sources[that.index])
                }
                // 清除错误，避免 error 事件在控制台抛出错误
                return null
            })

            this.player.reset()

            this.player.src(this.sources)

            this.player.load()
            this.player.play()
        },
        async uploadSuccess() {
            // 保存到数据库
            await saveAdminSectionRes({ cid: this.cid, sid: this.sid, resId: this.resId })
            // 更新标志位
            // this.isUploading = false
            this.isUploaded = true
            // 上传完成后，立即加载
            this.loadVideo()
        },
        handleCmd(data) {
            switch (data.cmd) {
                case 'save':
                    this.save()
                    break
            }
        },
        save() {
            //
        }
    },
    components: {
        Upload,
    }
}
</script>

<style lang="scss" scoped>
.video-js {
    .vjs-big-play-button {
        height: 3em;
        line-height: 3em;
        border-radius: 3em;
        margin-top: -1.5em;
        margin-left: -1.5em;
        .vjs-icon-placeholder {
        &:before {
            font-size: 80px;
        }
        }
    }
}

.video-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .content {
        width: 100%;
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        z-index: 100;

        .upload-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
        }

        .video-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(50, 50, 50, 0.95);
            overflow: hidden;

            .video-js {
                height: 0;
                //width: 85%;
                //padding-bottom: 53%; /*宽高比16:10*/
                width: 75%;
                padding-bottom: 43%; /*宽高比16:9*/
                cursor: pointer;
            }

            .play-btn {
                height: 90px;
                width: 90px;
                line-height: 80px;
                border-radius: 100px;
                border: 5px solid #fefefe;
                background-color: #fb8b18;
                padding: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                // position: absolute;
                // top: 39%;
            }

            .video-area {
                width: 85%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            .video-bg {
                background-color: #000;
                height: 0;
                width: 85%;
                padding-bottom: 53%; /*宽高比16:10*/
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .play-btn {
                    height: 40px;
                    width: 40px;
                    border-radius: 100px;
                    border: 5px solid #fefefe;
                    background-color: #fb8b18;
                    padding: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 39%;

                    .triangle-right {
                        width: 0;
                        height: 0;
                        border-top: 36px solid transparent;
                        border-left: 60px solid #fefefe;
                        border-bottom: 36px solid transparent;
                        margin-left: 17px;
                    }
                }
            }
        }
    }
}
</style>